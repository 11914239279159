<template>
  <div>
    <header-admin-pages
            title="Прямые трансляции"
            :counter="getLiveStreams.length"
            :button="headerButton"/>

    <div class="row">
      <div class="search-field">
        <label for="key">
          Поиск:
        </label>
        <input type="text" id="key" class="input blue" v-model="search.key">
      </div>

      <div class="search-field">
        <label for="key">
          Дата от:
        </label>
        <input type="date" id="date-start" class="input blue" v-model="search.date_start">
      </div>

      <div class="search-field">
        <label for="key">
          Дата до:
        </label>
        <input type="date" id="date-end" class="input blue" v-model="search.date_end">
      </div>

      <div class="search-field">
        <label for="rubric">
          Рубрика:
        </label>
        <select name="rubric" id="rubric" class="input blue" v-model="search.rubric">
          <option :value="null">
            -
          </option>
          <option :value="rubric.id" v-for="(rubric, index) in getRubrics" :key="`rubric${index}`">
            {{ rubric.title }}
          </option>
        </select>
      </div>

      <button class="btn-blue" @click="setSearch">
        Поиск
      </button>
    </div>

    <table>
      <tr>
        <td>Заголовок</td>
        <td>Описание</td>
        <td>Дата начала</td>
        <td>Дата окончания</td>
        <td class="drop-icon"></td>
      </tr>
      <tr v-for="liveStream in getLiveStreams" :key="`liveStream${liveStream.id}`">
        <td>{{ liveStream.title }}</td>
        <td>{{ liveStream.description }}</td>
        <td>{{ getFormattedDate(liveStream.start_date_time, true) }}</td>
        <td>{{ getFormattedDate(liveStream.end_date_time, true) }}</td>
        <td class="drop-icon">
          <drop-list @clickItem="clickItem"
                     :id-item="liveStream.id"
                     :edit-link="`/admin/live_stream/${liveStream.id}/edit`"/>
        </td>
      </tr>
    </table>

    <pagination class="pagination__block"
                :per-page="getAdminLiveStreamPagination.limit"
                :current="getAdminLiveStreamPagination.offset / getAdminLiveStreamPagination.limit + 1"
                :total="getAdminLiveStreamPagination.total"
                @page-changed="getPage"/>
  </div>
</template>

<script>
import HeaderAdminPages from '@/components/Admin/HeaderAdminPages'
import DropList from '@/components/Admin/DropList'
import formatDate from '@/mixins/formatDate'
import Pagination from '@/components/Pagination'

export default {
  name: 'LiveStreams',

  data () {
    return {
      headerButton: {
        title: 'Добавить прямую трансляцию',
        link: '/admin/live_stream/create'
      },
      search: {
        key: null,
        date_start: null,
        date_end: null,
        rubric: null
      }
    }
  },

  created () {
    this.$store.dispatch('getRubrics')
    this.$store.dispatch('getLiveStreams')
  },

  methods: {
    clickItem (value) {
      if (value.value === 'del') {
        const consent = confirm('Вы уверены, чтобы хотите удалить прямую трансляцию?')
        if (consent) {
          this.$store.dispatch('deleteLiveStream', value.id)
        } else {
          this.$noty.warning('Удаление отменено')
        }
      }
    },

    setSearch () {
      this.$store.commit('setAdminLiveStreamSearch', this.search)
      this.$store.dispatch('getLiveStreams')
    },

    getPage (page) {
      this.$store.commit('setAdminLiveStreamPaginationOffset', (page - 1) * this.getAdminLiveStreamPagination.limit)
      this.$store.dispatch('getLiveStreams')
    }
  },

  computed: {
    getLiveStreams () {
      return this.$store.getters.getLiveStreams
    },

    getRubrics () {
      return this.$store.getters.getRubrics
    },

    getAdminLiveStreamPagination () {
      return this.$store.getters.getAdminLiveStreamPagination
    }
  },

  components: {
    Pagination,
    DropList,
    HeaderAdminPages
  },

  mixins: [formatDate]
}
</script>

<style scoped lang="scss">
  .pagination__block {
    margin-top: 1rem;
  }

  .row {
    display: flex;
    margin-top: 1rem;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;

    .search-field {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: .5rem 0;

      label {
        text-align: left;
        font-size: .812rem;
        line-height: 154%;
        color: #5E686C;
      }
    }

    button {
      height: 3rem;
      min-width: 5rem;
      width: 10rem;
    }
  }

  table{
    margin-top: 2rem;
    border-spacing: 0;
    border-collapse: collapse;

    tr{
      padding: 0;
      border-bottom: 1px solid #D5D6D9;

      td {
        max-width: 23%;
        font-size: .812rem;
        line-height: 154%;
        color: #1F3245;
        padding: 1.375rem 1.375rem 1.375rem 0;

        a {
          color: #246CB7;
        }

        img {
          cursor: pointer;
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
        }
      }

      &:first-of-type td{
        padding: 1rem 0;
        color: #5E686C;
      }
    }
  }
</style>
